import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/zh";
import SEO from "../components/seo";

export default class TermsAndConditionsPage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const specific_date = new Date("2022-01-09");
    const current_date = new Date();
    if (current_date.getTime() > specific_date.getTime()) {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="Terms and Conditions "
            keyword="Terms and Conditions"
            decription="Terms and Conditions"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">条款与条件</h1>
              <p className="p--sm">
                阿雅娜会员优享计划，是一项针对科莫多维艾奇洙沙滩度假村。，阿雅娜lako
                di'a号豪华船宿，巴厘岛阿雅娜度假别墅，巴厘岛阿雅娜赛格拉酒店，巴厘岛阿雅娜度假酒店，巴厘岛阿雅娜林畔酒店，雅加达中诚广场酒店，Delonix
                Hotel
                Karawang酒店。,宾客长期的优惠政策，皆为阿雅娜会员提供优惠福利和周到服务。欢迎邮件至{" "}
                <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a>
                或登录 <a href="ayanarewards.com">ayanarewards.com</a>{" "}
                网站了解更多。
              </p>
              <h3>会员资格</h3>
              <ol>
                <li>
                  所有个人宾客均有资格成为阿雅娜会员。阿雅娜会员资格不可转让。会员不得在多个帐户中保持会员资格或賺取积分。
                </li>
                <li>
                  当积分达到一定的标准时会员可以升级到下一级别。每个会员级别有效期为一年。
                </li>
                <li>
                  一年内累计到5000积分的会员可升级为金卡会员，且升级之后每年累计1500积分即可维持会员等级。一年内累计到10000积分的会员可升级为铂金卡会员，且升级之后每年累计5000积分即可维持会员等级。积分累计不足将导致会员等级下降。
                </li>
                <li>阿雅娜积分有效期为自产生之时起的十（10）年或120个月。</li>
                <li>
                  若阿雅娜会员账户连续12个月没有使用将会失效。且最后一次使用日起12个月内未通过官网登陆使用、或未符合条件的住宿、或未参与符合条件的品牌消费的重新激活，阿雅娜会员账户将被停用。
                </li>
                <li>
                  当阿雅娜会员账户被停用时，不论积分的有效日期，任何累积积分都将被没收。
                </li>
                <li>
                  如果所提供的资料不准确、不完整，阿雅娜会员计划保留拒绝/终止会员资格或拒绝任何申请的权利且无需给出任何理由。
                </li>
              </ol>
              <h3>累计积分</h3>
              <ol>
                <li>
                  在参与计划酒店每消费10,000印尼盾即可获得1积分（在加拉璜凤凰木酒店每消费15,000印尼盾则可获得1积分），不含税金和服务费。带有小数点将四舍五入到最接近的整数。
                </li>
                <li>
                  阿雅娜会员金卡会员每消费10,000印尼盾将获得额外10％的奖励积分，铂金卡会员将获得额外25％的奖励积分。
                </li>
                <li>
                  满足下列条件的会员在参与计划酒店同入住日期间可赚取多达5间房间的积分：（1）每一间房间由该会员在退房时支付所有费用（2）并且入住预订的其中一间客房。
                </li>
                <li>
                  对于所有酒店，无论预订的价格是多少，通过任何第三方网站（GDS
                  除外）预订的会员都没有资格获得客房积分。所有会员仍可通过计入房间账单的有效消费赚取积分。
                </li>
                <li>
                  客人成为阿雅娜会员计划金卡会员后即可賺取积分。积分将在退房后的
                  7（七）个工作日内计入会员账户。
                </li>
                <li>
                  有效消费赚取积分：
                  <ul>
                    <li>
                      雅加达阿雅娜中城广场酒店
                      <ul>
                        <li>
                          大部分客房价格，除了部分房价不适用如员工价、含佣金价格、合同协议价、免费住宿奖励、主账户支付的房费账单、机务人员房价、现金和积分价格以及团体房价。预订不适用积分政策房价的会员仍可以通过有效消费赚取积分。酒店将在其系统中标注此类价格不可赚取积分。
                        </li>
                        <li>额外房间消费 (除迷你吧外)</li>
                        <li>餐饮消费 (除本膳和KaDo餐厅外)</li>
                        <li>疗护理消费</li>
                      </ul>
                    </li>
                    <li>
                      加拉璜凤凰木酒店
                      <ul>
                        <li>
                          大部分客房价格，除了部分房价不适用如免费住宿奖励、现金和积分价格。
                        </li>
                        <li>额外房间消费 </li>
                        <li>餐饮消费 (除Sakana餐厅外)</li>
                        <li>水疗护理消费</li>
                        <li>洗衣、干洗、商务中心、交通、损失和破碎费</li>
                      </ul>
                    </li>
                    <li>
                      巴厘岛阿雅娜度假别墅，巴厘岛阿雅娜赛格拉酒店，巴厘岛阿雅娜度假酒店，巴厘岛阿雅娜林畔酒店，科莫多维艾奇洙沙滩度假村。
                      <ul>
                        <li>仅限单独直接预订的房价，特殊价格除外。</li>
                        <li>额外房间消费 (除迷你吧外)</li>
                        <li>餐饮费用（不包括本膳餐厅）</li>
                        <li>水疗护理消费</li>
                      </ul>
                    </li>
                    <li>
                      阿雅娜Lako Di'a
                      <ul>
                        <li>
                          仅限单独直接预订套餐和过夜包船的费用，特殊价格以及任何其它消费包括酒精消费除外。
                        </li>
                      </ul>
                    </li>
                  </ul>
                  **税费和服务费以及其它杂费不适用于阿雅娜会员计划积分政策。
                </li>
                <li>
                  会员可登录阿雅娜会员门户网站-ayanarewards.com
                  -查看其阿雅娜会员积分余额和交易记录，或发送电子邮件至阿雅娜会员计划服务经理info@ayanarewards.com获取更新信息。
                </li>
                <li>
                  漏登阿雅娜奖励积分的会员务必在退房日期后30天内通知阿雅娜会员计划服务经理。如果未能及时通知会员服务团队，将导致无法获取相关阿雅娜奖励积分。会员可在退房后的7个工作日后核实积分。任何漏登阿雅娜奖励积分，可通发送电子邮件至阿雅娜会员计划服务：info@ayanarewards.com进行补登。
                </li>
                <li>
                  如果积分被误登记入账户，阿雅娜会员计划保留从会员账户余额中扣除相应积分的权利。
                </li>
              </ol>
              <h3>积分兑换</h3>
              <ol>
                <li>
                  会员可在门户网站ayanarewards.com下的兑换板块进行积分兑换。除了会员的账户和密码外，阿雅娜会员计划保留收集其它个人信息的权利以进行核验会员信息。
                </li>
                <li>
                  阿雅娜会员积分可兑换多重奖励礼遇，请参阅网站的奖励表获取兑换条款。
                </li>
                <li>
                  在兑换积分之前每位会员有义务查阅并遵循每项会员条款与条件。
                </li>
                <li>
                  对于住宿奖励，需要提前发送电子邮件至info@ayanarewards.com进行预订并说明首选入住日期。请注意，旺季期间可能会额外收取附加费。
                </li>
                <li>
                  所有积分兑换的福利不可退还，也不可取消。会员积分将在兑换时遵循兑换政策进行扣除。
                </li>
                <li>
                  福利自兑换之日起最长6个月内有效。未使用的奖励不得退还给会员账户或兑换现金。
                </li>
                <li>
                  参与积分兑换的伙伴退出或伙伴取消积分计划，可能会影响会员积分兑换。对此，阿雅娜会员计划将一概不负责。
                </li>
              </ol>
              <h3>通用规则</h3>
              <ol>
                <li>
                  当个人信息发生变化时，会员有责任更新其在阿雅娜会员计划门户中的会员信息，或以书面形式通知阿雅娜会员计划服务部。
                </li>
                <li>请妥善保管积分兑换凭证，该凭证不可替补。</li>
                <li>
                  阿雅娜会员计划服务办公室保留更正因处理不当造成的任何会员状态说明、会员应计积分、或会员积分余额的权利。
                </li>
                <li>
                  阿雅娜会员计划保留修改规则、限制、计划福利和特别优惠的权利，恕不另行通知。阿雅娜会员计划保留随时终止此项计划的权利。
                </li>
                <li>
                  阿雅娜会员计划奖励积分以及积分兑换均受适用法律法规的约束。
                </li>
                <li>
                  阿雅娜会员计划项目下获得的福利和奖励可能需要纳税。此类税费由上述福利或奖励的接收者自行承担。
                </li>
                <li>
                  阿雅娜会员计划不作任何明示或暗示的保证或陈述，并明确否认与通过阿雅娜会员计划索赔、兑换和/或享有的商品或服务质量有关的任何和所有责任(包括相应的损害)。在适用法律法规允许的最大范围内，法律所默示的所有条件、保证或其他条款均不包括在内。如果任何会员转让积分兑换项目，本条款也适用于受让人。
                </li>
                <li>
                  阿雅娜会员计划和/或参与此计划的酒店保留在必要时调整阿雅娜会员积分制度以适应不断变化的市场的权利。
                </li>
                <li>
                  有关阿雅娜会员奖励或奖励使用的欺诈或滥用行为，将由参与酒店和/或阿雅娜会员计划参与合作伙伴采取适当的行政和/或法律行动。
                </li>
                <li>本计划在法律禁止的情况下无效。</li>
                <li>
                  阿雅娜会员忠诚计划是一项由参与酒店运营的计划。服务提供商也可以访问会员的记录。
                </li>
                <li>
                  阿雅娜会员计划拥有本计划条款和条件的最终解释权。如果发生任何争议，会员需服从印度尼西亚法院的专属管辖权。
                </li>
              </ol>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="Terms and Conditions "
            keyword="Terms and Conditions"
            decription="Terms and Conditions"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">条款与条件</h1>
              <p className="p--sm">
                阿雅娜会员礼遇是一项常客计划，专为奖励入住阿雅娜科莫多维艾齐洙沙滩度假村、阿雅娜
                Lako
                di'a、巴厘岛阿雅娜度假别墅、巴厘岛阿雅娜水疗度假酒店、巴厘岛金巴兰RIMBA酒店、雅加达阿雅娜中城广场酒店和加拉璜凤凰木酒店的宾客而设计。以下条款和条件旨在保护参与阿雅娜会员礼遇的会员。如果您需要更详细的说明，请即刻发送电子邮件至
                <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a>
                或登录<a href="https://ayanarewards.com">ayanarewards.com</a>。
              </p>
              <h3>会员资格</h3>
              <ol>
                <li>
                  所有个人宾客均有资格成为阿雅娜会员礼遇会员。阿雅娜会员礼遇资格不可转让。会员不得在多个帐户中保持会员资格或获得积分。
                </li>
                {/* <li>
                    会员资格在两（2）年内有效。会员帐户将被停用，如果这些年度没有活动，所有累计积分将被没收</li> */}
                <li>
                  当积分达到一定的标准时会员可以升级到下一级别。每个会员级别有效期为一年。一年累计到5000积分的会员可升级为金卡会员，且升级之后每年累计1500积分即可维持会员等级。一年累计到10000积分的会员可升级为白金卡会员，且升级之后每年累计5000积分即可维持会员等级。积分累计不足将导致会员等级下降。
                </li>
                <li>
                  如果没有准确完整地提供所需信息，阿雅娜会员礼遇保留拒绝/终止会员资格或拒绝任何申请的权利而无需给出任何理由。
                </li>
              </ol>
              <h3>赚取积分</h3>
              <ol>
                <li>
                  会员在参与酒店和商店每次有效的消费10,000印尼盾（不含税费和服务费）立即获得1个积分。会员在凤凰木酒店和公寓每消费15,000印尼盾（不含税费和服务费）立即获得1个积分。积分数字将向下舍入到最接近的整数。
                </li>
                <li>
                  积分仅适用于以符合条件的价格入住，停留和支付房费的会员。当（1）每个房间由会员支付并且所有费用在结账时结算时（2）一个房间被会员占用，会员可以在参与酒店获得相同入住日期的最多五个房间的积分。
                </li>
                <li>
                  阿雅娜会员优享计划积分有效期为产生积分后的10年或120个月。
                </li>
                <li>
                  对于所有酒店，无论预订房价如何，通过任何第三方网站（GDS除外）预订的会员均无资格获得其房间积分。所有会员仍可通过其客房其他符合资格的消费获得积分。
                </li>
                <li>
                  客人成为会员后即可获得积分。
                  <span
                    lang="ZH-CN"
                    style={{ fontSize: "11pt", fontFamily: "PMingLiU, serif" }}>
                    积分将在结账离店的
                  </span>
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Calibri, sans-serif",
                    }}>
                    7
                  </span>
                  <span
                    lang="ZH-CN"
                    new=""
                    style={{ fontSize: "11pt", fontFamily: "" }}
                    times="">
                    个工作日内到
                  </span>
                  <span
                    lang="ZH-CN"
                    style={{ fontSize: "11pt", fontFamily: "PMingLiU, serif" }}>
                    账
                  </span>
                  。
                </li>
                <li>
                  <ul>
                    <li>符合条件的消费：</li>
                    <li>
                      雅加达阿雅娜中城广场酒店
                      <br />
                      o大多数房价
                      <br />
                      本计划允许会员通过大多数房价赚取积分，除不适用房价外，如员工价、佣金价、批发价、免费住宿奖励、计入主帐户的房价、乘务员价、现金/积分房价和团体价。支付不适用房价的会员仍可通过符合条件的其他消费赚取积分。酒店有责任在其酒店系统内将这些房价标记为不可赚取积分。
                      <br />
                      o食品和饮品、排除 HonZen and KaDo 。<br />
                      o未入住费用、提前离开和取消费用
                    </li>
                    <li>
                      加拉璜凤凰木酒店
                      <br />
                      o所有消费均符合资格 (excluding Sakana Restaurant charges)
                    </li>
                    <li>
                      巴厘岛阿雅娜水疗度假酒店、巴厘岛阿雅娜度假别墅、巴厘岛金巴兰RIMBA酒店
                      <br />
                      o仅有个人直接预订房价符合资格,&nbsp;除特惠价项目外
                    </li>
                    <li>
                      阿雅娜Lako
                      Di'a号：套餐费用和游船包租只能通过个人直接预订进行，特别房价以及任何其他消费（包括酒精饮料消费）除外
                    </li>
                    <li>
                      **服务费、税费和其他杂费不适用于阿雅娜会员礼遇积分。
                    </li>
                  </ul>
                </li>
                <li>
                  会员可登录阿雅娜会员礼遇门户网站ayanarewards.com查看其阿雅娜会员礼遇积分余额和交易行为，或发送电子邮件至阿雅娜会员礼遇服务经理info@ayanarewards.com以获取最新信息。
                </li>
                <li>
                  积分未计入其阿雅娜会员礼遇计划的会员必须在退房日期后30天内通知阿雅娜会员礼遇服务经理。未通知会员服务团队将导致相关阿雅娜会员礼遇积分无法计入帐户。结账离店的7个工作日后会员可登陆会员账户查询积分累计
                  <br />
                  。任何关于未计入阿雅娜会员礼遇积分的申诉都可以发送电子邮件至ayanarewards@midplaza.com转发给阿雅娜会员礼遇会员服务部。
                </li>
                <li>
                  如果积分因各种问题记错到会员的账户上，会员理解阿雅娜会员优享计划具有全权把相应积分从会员的帐户余额中扣除。
                </li>
              </ol>
              <h3>领取奖励</h3>
              <ol>
                <li>
                  会员可登录ayanarewards.com在会员门户 &ndash;
                  奖励/兑换部分使用其阿雅娜会员礼遇积分兑换奖励。除会员用户名和密码外，阿雅娜会员礼遇保留要求获取其他个人信息以进行验证的权利。
                </li>
                <li>
                  最低阿雅娜会员礼遇积分适用于所有奖励。有关兑换要求请查看网站的奖励表。
                </li>
                <li>
                  在要求奖励之前，会员有义务始终参考每个奖励中所述的条款和条件。
                </li>
                <li>
                  对于夜间住宿奖励，需要通过发送电子邮件至info@ayanarewards.com进行预订，并说明首选入住日期。请注意，旺季期间可能需要额外收费。
                </li>
                <li>
                  所有奖励均不可退款且不可取消。会员积分将在兑换时扣除，并将遵循兑换政策。
                </li>
                <li>
                  自兑换日期起，奖励有效期最长为6个月。未使用的奖励不能退回会员账户或兑换现金。
                </li>
                <li>
                  阿雅娜会员礼遇不对兑换伙伴退出计划或合作伙伴取消奖励的事宜负责，这可能会影响所提供的奖励。
                </li>
              </ol>
              <h3>一般性规则</h3>
              <ol>
                <li>
                  当个人资料发生变更时，会员有责任在阿雅娜会员礼遇门户网站上更新其会员信息或以书面形式通知阿雅娜会员礼遇会员服务部。
                </li>
                <li>会员应全权负责保管其奖励证书。奖励证书不得补发。</li>
                <li>
                  阿雅娜会员礼遇会员服务办公室保留更正任何因处理错误而不正确的任何会员声明或阿雅娜会员礼遇积分收益或余额的权利。
                </li>
                <li>
                  阿雅娜会员礼遇保留修改规则、限制、计划权益和特别优惠的权利，恕不另行通知。
                  阿雅娜会员礼遇保留随时终止阿雅娜会员礼遇计划的权利。
                </li>
                <li>
                  阿雅娜会员礼遇的积分收益和奖励的兑换受所有适用法律和法规的约束。
                </li>
                <li>
                  从阿雅娜会员礼遇获取的福利和奖励可能需要缴纳税款。获取上述福利或奖励的会员应全权负责缴纳此税款。
                </li>
                <li>
                  阿雅娜会员礼遇不做任何明示性或暗示性保证或陈述，并明确拒绝对通过阿雅娜会员礼遇获取、兑换和/或享受的商品或服务的质量承担任何责任（包括间接损害）。在适用法律和法规允许下，任何法律暗示隐含的条件、保证或其他条款均尽最大程度排除。如果任何会员转让奖励证书的使用权，本条款也适用于受让人。
                </li>
                <li>
                  参与酒店和/或阿雅娜会员礼遇保留在必要时调整阿雅娜会员礼遇积分价值以反映不断变化的市场条件的权利。
                </li>
                <li>
                  有关阿雅娜会员礼遇或奖励使用的欺诈或滥用行为受到参与酒店和/或阿雅娜会员礼遇参与合作伙伴的适当行政和/或法律诉讼的约束。
                </li>
                <li>在法律禁止的情况下，本计划无效。</li>
                <li>
                  阿雅娜会员礼遇忠诚计划是由参与酒店执行的计划。服务提供商也可以访问会员记录。
                </li>
                <li>
                  对计划条款和条件的所有解释均由阿雅娜会员礼遇全权自行决定。如有任何争议，会员同意接受印度尼西亚法院的专属管辖权管辖。
                </li>
              </ol>
            </div>
          </div>
          <br />
        </Layout>
      );
    }
  }
}
